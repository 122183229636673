* {
    margin: 0px;
    // scroll-behavior: smooth;
}

@font-face {
    font-family: "Airbnb Cereal App Book";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Book"), url("../../fonts/AirbnbCerealBook.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Light";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Light"), url("../../fonts/AirbnbCerealLight.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Medium"), url("../../fonts/AirbnbCerealMedium.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Bold"), url("../../fonts/AirbnbCerealBold.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Extra Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Extra Bold"), url("../../fonts/AirbnbCerealExtraBold.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Black";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Black"), url("../../fonts/AirbnbCerealBlack.woff") format("woff");
}

//Background colors
.bg-green1 {
    background-color: $green1_color !important;
    color: white !important;
}

.bg-green2 {
    background-color: $green2_color !important;
    color: white !important;
}

.bg-green3 {
    background-color: $green3_color !important;
}

.bg-white {
    background-color: $white_color !important;
    color: black !important;
}

.bg-red {
    background-color: $red_color !important;
    color: white !important;
}
.bg-yellow{
    background-color:#fafa5c !important;
}
.bg-orange{
    background-color:#EFAA71 !important;
}
//Text
.title-text {
    font-family: $Bold !important;
    font-size: 1.9rem;
}

.subtitle-text {
    font-family: $Light !important;
    font-size: 1rem;
}

.info-text {
    font-family: $Light !important;
    font-size: 1rem;
}

.txt-justify {
    text-align: justify !important;
}

.txt-c {
    text-align: center !important;
}

.txt-r {
    text-align: right !important;
}

.txt-l {
    text-align: left !important;
}

//Margin
.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.25rem !important;
}

.mt-5 {
    margin-top: 1.5rem !important;
}

.mt-10 {
    margin-top: 2rem !important;
}
.mt-15 {
    margin-top: 4rem !important;
}
.mt-20 {
    margin-top: 100px !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.25rem !important;
}

.mb-5 {
    margin-bottom: 1.5rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.25rem !important;
}

.ml-5 {
    margin-left: 1.5rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.25rem !important;
}

.mr-5 {
    margin-right: 1.5rem !important;
}

//Img
.img-responsive {
    width: 100%;
    height: auto;
}

.border-rounded {
    border-radius: 10px;
}
.col-container {
    display: table !important;
}
.col {
    display: table-cell !important;
}

//cursor

.pointer{
    cursor: pointer;
}


//iframe

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .container-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
  }


  //calendar

  .rbc-calendar{
    height: 80vh;
}

.swal2-container {
    z-index: 200000 !important;
  }
.ui__navbar-text-left {
  font-family: $Bold !important;
  font-size: 18px !important;
  text-align: center;
}

.ui__navbar-text-right {
  font-family: $ExtraBold !important;
  font-size: 13px !important;
  color: white !important;
}

// arrows
.ui__arrow-container {
  text-align: center;
  position: absolute;
  cursor: pointer;
  top: 90% !important;
  width: 100%;
}

.ui__arrow {
  border-radius: 50px;
  color: white;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(0, 0, 0);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.main__overflow-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
}
.main__title {
    flex-grow: 1;
}
.main__img-wrapper {
    // background: linear-gradient(rgba(106, 153, 78, 0.12), rgba(188, 71, 73, 0.12)),
    //     url(https://res.cloudinary.com/djmhhp7hp/image/upload/v1631402956/finca-buena-vista/cabana_ldtpj7.jpg);
    position: relative;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main__jumbotron-text {
    h1 {
        font-family: $Light;
        font-size: 2em;
        // letter-spacing: -2px;
        // border-bottom: 2px solid $red_color;
        color: $green1_color;
        text-transform: uppercase;
        text-align: center;
    }
    p {
        font-family:  $Light;
        font-size: 1.5rem;
        text-align: justify;
        // color: $green1_color;
    }
}

.main__outer {
    display: table;
    position: absolute;
    top: 10;
    left: 0;
    height: 50%;
    width: 100%;
}

.main__middle {
    display: table-cell;
    vertical-align: middle;
}

.main__inner {
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
}

.fa {
    padding: 20px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 10px 10px;
    border-radius: 40px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
    .fa-youtube {
    background: #bb0000;
    color: white;
  }
  
  .fa-instagram {
    background: #e95950	;
    color: white;
  }
  .fa-whatsapp{
    background: #25D366;
    color: white;
  }
